'use client';

import { useEffect, useRef } from 'react';
import * as THREE from 'three';

const ParticleBackground = () => {
  const containerRef = useRef(null);
  
  useEffect(() => {
    if (!containerRef.current) return;
    
    // Main parameters
    const PARTICLE_SPACING = 24;
    const MAX_DISPLACEMENT = 115;
    const RANDOM_FORCE_STRENGTH = 0.005;
    const CURSOR_ATTRACTION_RADIUS = 550;
    const CURSOR_ATTRACTION_STRENGTH = 0.45;
    const RETURN_FORCE = 0.015;
    const MIN_PARTICLE_SIZE = 1.4;
    const MAX_PARTICLE_SIZE = 5;
    const MIN_RCOLOR = 0.445;
    const MAX_RCOLOR = 0.935;
    const MIN_GCOLOR = 0.495;
    const MAX_GCOLOR = 0.915;
    const MIN_BCOLOR = 0.345;
    const MAX_BCOLOR = 0.685;
    const MIN_OPACITY = 0.6;
    const MAX_OPACITY = 1.0;
    const PARTICLE_SEGMENTS = 6;

    // Three.js setup
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      66, 
      window.innerWidth / window.innerHeight, 
      1, 
      1000
    );
    const renderer = new THREE.WebGLRenderer({ 
      antialias: true,
      alpha: true // Make background transparent
    });
    
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0); // Transparent background
    containerRef.current.appendChild(renderer.domElement);

    // Camera position
    camera.position.z = 950;
    
    // Create a glow layer for cursor effect
    const createGlowLayer = () => {
      const glowScene = new THREE.Scene();
      const glowGeometry = new THREE.PlaneGeometry(200, 200);
      
      // Create a radial gradient texture for the glow
      const canvas = document.createElement('canvas');
      canvas.width = 256;
      canvas.height = 256;
      const context = canvas.getContext('2d');
      
      // Create radial gradient
      const gradient = context.createRadialGradient(
        canvas.width / 2, canvas.height / 2, 0,
        canvas.width / 2, canvas.height / 2, canvas.width / 2
      );
      
      // Set gradient colors
      gradient.addColorStop(0, 'rgba(255, 255, 255, 1.0)');
      gradient.addColorStop(0.2, 'rgba(200, 230, 255, 0.8)');
      gradient.addColorStop(0.5, 'rgba(150, 200, 255, 0.4)');
      gradient.addColorStop(1, 'rgba(100, 150, 255, 0)');
      
      // Fill with gradient
      context.fillStyle = gradient;
      context.fillRect(0, 0, canvas.width, canvas.height);
      
      // Create a texture from the canvas
      const glowTexture = new THREE.CanvasTexture(canvas);
      
      // Create material with the texture
      const glowMaterial = new THREE.MeshBasicMaterial({
        map: glowTexture,
        transparent: true,
        blending: THREE.AdditiveBlending,
        depthWrite: false,
        depthTest: false,
        side: THREE.DoubleSide
      });
      
      // Create mesh
      const glowMesh = new THREE.Mesh(glowGeometry, glowMaterial);
      glowMesh.visible = false; // Hide initially
      
      // Add to scene
      glowScene.add(glowMesh);
      
      return { scene: glowScene, mesh: glowMesh };
    };
    
    const glowLayer = createGlowLayer();
    
    // Grid dimensions calculation
    const calculateGridDimensions = () => {
      const gridWidth = window.innerWidth + PARTICLE_SPACING * 2;
      const gridHeight = window.innerHeight + PARTICLE_SPACING * 2;
      
      const columnsCount = Math.ceil(gridWidth / PARTICLE_SPACING);
      const rowsCount = Math.ceil(gridHeight / PARTICLE_SPACING);
      
      return {
        width: gridWidth,
        height: gridHeight,
        columns: columnsCount,
        rows: rowsCount
      };
    };

    // Mouse tracking
    const mouse = new THREE.Vector2();
    mouse.x = 0;
    mouse.y = 0;

    // Particle system setup
    let particles = [];
    let instancedMesh;
    
    // Create circle geometry for particles
    const createCircleGeometry = () => {
      return new THREE.CircleGeometry(1, PARTICLE_SEGMENTS);
    };
    
    // Create or recreate particle system
    const createParticleSystem = () => {
      // Clear existing particles
      if (instancedMesh) {
        scene.remove(instancedMesh);
        instancedMesh.geometry.dispose();
        instancedMesh.material.dispose();
        particles = [];
      }
      
      // Calculate dimensions
      const grid = calculateGridDimensions();
      const particleCount = grid.columns * grid.rows;
      
      // Create geometry
      const circleGeometry = createCircleGeometry();
      
      // Create material
      const material = new THREE.MeshBasicMaterial({
        transparent: true,
        opacity: MIN_OPACITY,
        blending: THREE.AdditiveBlending,
        side: THREE.DoubleSide
      });
      
      // Create instanced mesh
      instancedMesh = new THREE.InstancedMesh(
        circleGeometry,
        material,
        particleCount
      );
      
      // Set instance colors
      instancedMesh.instanceColor = new THREE.InstancedBufferAttribute(
        new Float32Array(particleCount * 3),
        3
      );
      
      // Setup instances
      const matrix = new THREE.Matrix4();
      const color = new THREE.Color();
      
      // Create grid of particles
      let i = 0;
      for (let x = 0; x < grid.columns; x++) {
        for (let y = 0; y < grid.rows; y++) {
          const xPos = x * PARTICLE_SPACING - grid.width / 2;
          const yPos = y * PARTICLE_SPACING - grid.height / 2;
          const zPos = 0;
          
          // Generate random color
          const r = MIN_RCOLOR + Math.random() * (MAX_RCOLOR - MIN_RCOLOR);
          const g = MIN_GCOLOR + Math.random() * (MAX_GCOLOR - MIN_GCOLOR);
          const b = MIN_BCOLOR + Math.random() * (MAX_BCOLOR - MIN_BCOLOR);
          
          // Set instance color
          color.setRGB(r, g, b);
          instancedMesh.setColorAt(i, color);
          
          // Set instance position and scale
          matrix.makeTranslation(xPos, yPos, zPos);
          matrix.scale(new THREE.Vector3(MIN_PARTICLE_SIZE, MIN_PARTICLE_SIZE, 1));
          instancedMesh.setMatrixAt(i, matrix);
          
          // Store particle info
          particles.push({
            index: i,
            velocity: new THREE.Vector3(0, 0, 0),
            originalPosition: new THREE.Vector3(xPos, yPos, zPos),
            size: MIN_PARTICLE_SIZE,
            originalColor: { r, g, b },
            currentOpacity: MIN_OPACITY,
            currentSize: MIN_PARTICLE_SIZE
          });
          
          i++;
        }
      }
      
      // Add to scene
      scene.add(instancedMesh);
    };
    
    // Initialize particle system
    createParticleSystem();
    
    // Mouse movement handler
    function onMouseMove(event) {
      // Convert mouse coordinates to normalized device coordinates (-1 to +1)
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
      
      // Convert to scene coordinates
      const vector = new THREE.Vector3(mouse.x, mouse.y, 0);
      vector.unproject(camera);
      vector.sub(camera.position).normalize();
      const distance = -camera.position.z / vector.z;
      mouse.worldPosition = camera.position.clone().add(vector.multiplyScalar(distance));
      
      // Update glow position to follow cursor
      glowLayer.mesh.position.copy(mouse.worldPosition);
    }
    
    // Resize handler
    function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
      
      // Recreate particle system with new dimensions
      createParticleSystem();
    }
    
    // Add event listeners
    window.addEventListener('mousemove', onMouseMove, false);
    window.addEventListener('resize', onWindowResize, false);
    
    // Animation variables
    let particlesNearCursor = 0;
    let cursorIntensity = 0;
    
    // Animation loop
    function animate() {
      const animationId = requestAnimationFrame(animate);
      
      // Update each particle
      const matrix = new THREE.Matrix4();
      const position = new THREE.Vector3();
      const quaternion = new THREE.Quaternion();
      const scale = new THREE.Vector3();
      const color = new THREE.Color();
      
      // Reset tracking variables
      cursorIntensity = 0;
      particlesNearCursor = 0;
      let avgCursorColor = new THREE.Color(0, 0, 0);
      
      // Process each particle
      for (let i = 0; i < particles.length; i++) {
        const particle = particles[i];
        const index = particle.index;
        
        // Get current position and scale from matrix
        instancedMesh.getMatrixAt(index, matrix);
        matrix.decompose(position, quaternion, scale);
        
        // Apply random force
        const randomForce = new THREE.Vector3(
          (Math.random() - 0.5) * RANDOM_FORCE_STRENGTH,
          (Math.random() - 0.5) * RANDOM_FORCE_STRENGTH,
          0
        );
        
        // Apply return force to original position
        const originalPos = particle.originalPosition;
        const displacementVector = new THREE.Vector3(
          originalPos.x - position.x,
          originalPos.y - position.y,
          originalPos.z - position.z
        );
        const returnForce = displacementVector.multiplyScalar(RETURN_FORCE);
        
        // Apply cursor attraction if within radius
        let cursorForce = new THREE.Vector3(0, 0, 0);
        let distanceToCursor = Infinity;
        
        if (mouse.worldPosition) {
          distanceToCursor = mouse.worldPosition.distanceTo(position);
          if (distanceToCursor < CURSOR_ATTRACTION_RADIUS) {
            // Attraction decreases with distance
            const attractionStrength =
              (1 - distanceToCursor / CURSOR_ATTRACTION_RADIUS) * CURSOR_ATTRACTION_STRENGTH;
            
            cursorForce = new THREE.Vector3(
              mouse.worldPosition.x - position.x,
              mouse.worldPosition.y - position.y,
              0
            ).normalize().multiplyScalar(attractionStrength);
          }
        }
        
        // Apply glow effect based on cursor distance
        if (distanceToCursor < CURSOR_ATTRACTION_RADIUS) {
          // Calculate intensity based on distance (closer = more intense)
          const intensity = 1 - (distanceToCursor / CURSOR_ATTRACTION_RADIUS);
          
          // Track overall cursor intensity
          cursorIntensity += intensity;
          particlesNearCursor++;
          
          // Calculate new size based on cursor proximity
          const newSize = MIN_PARTICLE_SIZE + intensity * (MAX_PARTICLE_SIZE - MIN_PARTICLE_SIZE);
          particle.currentSize = newSize;
          
          // Enhance the particle's color for glow effect
          const original = particle.originalColor;
          const glowFactor = intensity * 1.25;
          
          // Brightened color
          const r = Math.min(original.r * (1 + glowFactor), 1.0);
          const g = Math.min(original.g * (1 + glowFactor), 1.0);
          const b = Math.min(original.b * (1 + glowFactor), 1.0);
          
          color.setRGB(r, g, b);
          instancedMesh.setColorAt(index, color);
          
          // Accumulate average color near cursor for glow
          avgCursorColor.r += r * intensity;
          avgCursorColor.g += g * intensity;
          avgCursorColor.b += b * intensity;
          
          // Store current opacity value for this particle
          particle.currentOpacity = MIN_OPACITY + intensity * (MAX_OPACITY - MIN_OPACITY);
        } else {
          // Reset to original color when outside cursor radius
          const original = particle.originalColor;
          color.setRGB(original.r, original.g, original.b);
          instancedMesh.setColorAt(index, color);
          
          // Reset size
          particle.currentSize = MIN_PARTICLE_SIZE;
          
          // Reset opacity
          particle.currentOpacity = MIN_OPACITY;
        }
        
        // Update velocity
        particle.velocity.add(randomForce);
        particle.velocity.add(returnForce);
        particle.velocity.add(cursorForce);
        
        // Apply damping
        particle.velocity.multiplyScalar(0.95);
        
        // Update position
        const newPosition = position.clone().add(particle.velocity);
        
        // Ensure particle doesn't move beyond MAX_DISPLACEMENT from original position
        const displacement = newPosition.distanceTo(particle.originalPosition);
        if (displacement > MAX_DISPLACEMENT) {
          const direction = newPosition.clone().sub(particle.originalPosition).normalize();
          newPosition.copy(particle.originalPosition).add(
            direction.multiplyScalar(MAX_DISPLACEMENT)
          );
          
          // Reflect velocity to bounce off the boundary
          const normal = particle.originalPosition.clone().sub(newPosition).normalize();
          particle.velocity.reflect(normal).multiplyScalar(0.5);
        }
        
        // Update matrix with new position and size
        matrix.makeTranslation(newPosition.x, newPosition.y, newPosition.z);
        matrix.scale(new THREE.Vector3(
          particle.currentSize,
          particle.currentSize,
          1
        ));
        instancedMesh.setMatrixAt(index, matrix);
      }
      
      // Update the instanced mesh buffers
      instancedMesh.instanceMatrix.needsUpdate = true;
      instancedMesh.instanceColor.needsUpdate = true;
      
      // Apply average opacity to material
      let totalOpacity = 0;
      
      particles.forEach(particle => {
        if (particle.currentOpacity > MIN_OPACITY) {
          totalOpacity += particle.currentOpacity;
        }
      });
      
      // If particles are near cursor, adjust global opacity and show glow
      if (particlesNearCursor > 0) {
        const avgOpacity = totalOpacity / particlesNearCursor;
        instancedMesh.material.opacity = avgOpacity;
        
        // Update glow layer
        glowLayer.mesh.visible = true;
        
        // Calculate average cursor color for glow
        avgCursorColor.r /= particlesNearCursor;
        avgCursorColor.g /= particlesNearCursor;
        avgCursorColor.b /= particlesNearCursor;
        
        // Set glow color based on nearby particles
        glowLayer.mesh.material.color = avgCursorColor;
        
        // Calculate glow size based on cursor intensity
        const avgIntensity = cursorIntensity / particlesNearCursor;
        const glowSize = 200 + avgIntensity * 300;
        glowLayer.mesh.scale.set(glowSize / 200, glowSize / 200, 1);
        
        // Set opacity based on intensity
        glowLayer.mesh.material.opacity = 0.3 + avgIntensity * 0.3;
      } else {
        instancedMesh.material.opacity = MIN_OPACITY;
        glowLayer.mesh.visible = false;
      }
      
      // Render the main scene
      renderer.render(scene, camera);
      
      // Render the glow layer on top
      if (glowLayer.mesh.visible) {
        // Disable depth testing for glow layer
        const originalDepthTest = renderer.state.buffers.depth.test;
        renderer.state.buffers.depth.test = false;
        
        renderer.autoClear = false;
        renderer.render(glowLayer.scene, camera);
        renderer.autoClear = true;
        
        // Restore depth test state
        renderer.state.buffers.depth.test = originalDepthTest;
      }
    }
    
    // Start animation
    animate();
    
    // Cleanup function
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('resize', onWindowResize);
      
      // Dispose Three.js resources
      scene.remove(instancedMesh);
      if (instancedMesh) {
        instancedMesh.geometry.dispose();
        instancedMesh.material.dispose();
      }
      
      glowLayer.scene.remove(glowLayer.mesh);
      glowLayer.mesh.geometry.dispose();
      glowLayer.mesh.material.dispose();
      
      renderer.dispose();
      
      if (containerRef.current && containerRef.current.contains(renderer.domElement)) {
        containerRef.current.removeChild(renderer.domElement);
      }
    };
  }, []);

  return (
    <div 
      ref={containerRef}
      className="fixed top-0 left-0 w-full h-full -z-10" 
      style={{ pointerEvents: 'none' }}
    />
  );
};

export default ParticleBackground;
